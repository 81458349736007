"use strict";
import Fuse from "fuse.js";
import type { FuseResultMatch, FuseResult as FuseResultType } from "fuse.js";
import { useState, useCallback, useMemo, useEffect, memo } from "react";
import { titleCase } from "../../utils/text";
import { track } from "@utils/track";
import type { Parada } from "@utils/api";
import {
  getLangFromUrl,
  useTranslatedPath,
  useTranslations,
} from "@i18n/utils";

const lang = getLangFromUrl(new URL(window.location.href));
const translatePath = useTranslatedPath(lang);

type FuseResult<T> = FuseResultType<T>;

const Buscador = () => {
  const t = useTranslations(lang);
  const [result, setResult] = useState<FuseResult<Parada>[]>([]);
  const [data, setData] = useState<Parada[]>([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);

  // Helper function to highlight matched text
  const highlightMatch = (
    text: string,
    matches?: readonly FuseResultMatch[]
  ) => {
    if (!matches) return text;

    const match = matches.find((m) => m.key === "descripcion");
    if (!match) return text;

    let result = text;
    const indices = match.indices;
    for (let i = indices.length - 1; i >= 0; i--) {
      const [start, end] = indices[i];
      result =
        result.slice(0, start) +
        `<mark class="bg-transparent border-b-2 border-red-700">${result.slice(
          start,
          end + 1
        )}</mark>` +
        result.slice(end + 1);
    }
    return result;
  };

  // Memoize Fuse instance and options
  const options = useMemo(
    () => ({
      includeMatches: true,
      keys: ["id", "descripcion", "conexiones.lineId"],
    }),
    []
  );

  const fuse = useMemo(() => new Fuse(data, options), [data, options]);

  // Cleanup function for blur timeout
  useEffect(() => {
    return () => {
      if (blurTimeout) clearTimeout(blurTimeout);
    };
  }, []);

  const handleFocus = useCallback(
    async (e: React.FocusEvent<HTMLInputElement>) => {
      if (data.length > 0) return;
      setLoading(true);
      try {
        const response = await fetch("/api/paradas.json");
        const raw = await response.json();
        const filteredData = raw.filter(
          (parada: Parada) => parada.conexiones?.length > 0
        );
        setData(filteredData);
        // Track search initialization with available stops count
        track("search-init", {
          totalStops: filteredData.length,
        });
      } catch (error) {
        console.error("Error fetching paradas:", error);
        setData([]);
        // Track search initialization error
        track("search-init-error", {
          error: error instanceof Error ? error.message : "Unknown error",
        });
      } finally {
        setLoading(false);
        e.target.focus();
      }
    },
    [data.length]
  );

  let blurTimeout: NodeJS.Timeout;

  const handleClean = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLButtonElement>) => {
      clearTimeout(blurTimeout);
      blurTimeout = setTimeout(() => {
        if (e.currentTarget instanceof HTMLInputElement) {
          e.currentTarget.value = "";
        }
        // Track search cancellation with current term and available results
        track("search-clear", {
          term,
          availableResults: result.map((r) => r.item.id).join(","),
          resultCount: result.length,
        });
        setResult([]);
        setTerm("");
      }, 100);
      if (e.currentTarget instanceof HTMLInputElement) {
        e.currentTarget.blur();
      }
    },
    [term, result]
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (!value) {
        handleClean(e);
        return;
      }
      const searchResults = fuse.search(value);
      setResult(searchResults);
      setTerm(value);

      // Track each search with term and results
      track("search", {
        term: value,
        resultCount: searchResults.length,
        topResults: searchResults
          .slice(0, 5)
          .map((r) => r.item.id)
          .join(","),
      });

      // Track specifically when searches yield no results
      if (searchResults.length === 0) {
        track("search-no-results", {
          term: value,
          totalAvailableStops: data.length,
        });
      }
    },
    [fuse, handleClean, data.length]
  );

  const handleClickResult = useCallback(
    (paradaId: string) => {
      // Track result click with search context
      track("search-click", {
        term,
        clickedId: paradaId,
        position: result.findIndex((r) => r.item.id === paradaId),
        availableResults: result.map((r) => r.item.id).join(","),
        resultCount: result.length,
      });
    },
    [term, result]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!result.length && e.key === "Escape") {
        e.preventDefault();
        handleClean(e as unknown as React.SyntheticEvent<HTMLInputElement>);
        return;
      }

      if (!result.length) return;

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setActiveIndex((prev) =>
            prev < result.slice(0, 5).length - 1 ? prev + 1 : prev
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setActiveIndex((prev) => (prev > 0 ? prev - 1 : prev));
          break;
        case "Enter":
          if (activeIndex >= 0) {
            e.preventDefault();
            const selectedParada = result[activeIndex].item;
            window.location.href = translatePath(
              `/parada/${selectedParada.id}/`
            );
            handleClickResult(selectedParada.id);
          }
          break;
        case "Escape":
          e.preventDefault();
          setResult([]);
          setActiveIndex(-1);
          handleClean(e as unknown as React.SyntheticEvent<HTMLInputElement>);
          break;
      }
    },
    [result, activeIndex, handleClickResult, handleClean]
  );

  // Memoize search results list
  const searchResults = useMemo(
    () =>
      result.length > 0 ? (
        <ul
          id="suggestion-list"
          className="absolute z-10 w-full translate-y-2 rounded-lg shadow-2xl top-full bg-slate-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-400"
          role="listbox"
          aria-label={t("search.aria.suggestions")}
          aria-multiselectable="false"
        >
          {result
            .slice(0, 10)
            .map((parada: FuseResult<Parada>, index: number) => {
              const {
                item: { id, descripcion, conexiones },
              } = parada;
              if (!conexiones) return null;
              return (
                <li
                  key={id}
                  id={`parada-${id}`}
                  className={`px-2 py-1 border-b-2 cursor-pointer last:border-b-0 border-slate-100 dark:border-gray-700 ${
                    index === activeIndex ? "bg-slate-200 dark:bg-gray-600" : ""
                  } focus:bg-red-50 focus:outline-hidden focus:ring-2 focus:ring-red-700 dark:focus:bg-gray-600 dark:focus:ring-red-500 hover:bg-slate-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white transition-colors duration-150`}
                  role="option"
                  aria-selected={index === activeIndex}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleClickResult(id);
                      window.location.href = translatePath(`/parada/${id}/`);
                    }
                  }}
                >
                  <a
                    href={translatePath(`/parada/${id}/`)}
                    onClick={() => handleClickResult(id)}
                    className="flex items-center justify-between px-2 py-2"
                  >
                    <span className="inline-block w-10 px-2 py-1 mr-2 text-xs font-bold text-center text-white bg-red-700 rounded-lg">
                      {id}
                    </span>
                    <div className="pl-4 grow">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlightMatch(
                            titleCase(descripcion.toLowerCase()),
                            parada.matches
                          ),
                        }}
                      />
                    </div>
                    <ul className="flex text-slate-700 dark:text-slate-300">
                      {conexiones.map((conexion) => (
                        <li className="ml-2" key={`${id}-${conexion.lineId}`}>
                          {conexion.lineId}
                        </li>
                      ))}
                    </ul>
                  </a>
                </li>
              );
            })}
        </ul>
      ) : null,
    [result, handleClickResult, activeIndex]
  );

  return (
    <div className="relative w-full mt-0 mb-10 md:my-10 sm:max-w-full">
      <form role="search" onSubmit={(e) => e.preventDefault()}>
        <div className="relative">
          <input
            type="search"
            role="combobox"
            aria-label={t("search.aria.label")}
            aria-autocomplete="list"
            aria-controls="suggestion-list"
            aria-expanded={result.length > 0}
            aria-haspopup="listbox"
            aria-owns="suggestion-list"
            placeholder={t("search.placeholder")}
            onChange={handleSearch}
            onFocus={handleFocus}
            onEmptied={handleClean}
            value={term}
            className={`px-6 py-3 w-full rounded-lg shadow-lg border-0 text-xl text-center
              focus:outline-hidden focus:ring-2 focus:ring-red-700 bg-white
              dark:focus:bg-gray-700 dark:focus:ring-red-500
              hover:ring-1 hover:ring-red-600 hover:bg-slate-50
              dark:hover:bg-gray-700 dark:hover:ring-red-400
              dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300
              transition-all duration-150 ${loading ? "animate-pulse" : ""}`}
            autoComplete="off"
            onKeyDown={handleKeyDown}
            aria-activedescendant={
              activeIndex >= 0
                ? `parada-${result[activeIndex].item.id}`
                : undefined
            }
          />
          {term && (
            <button
              type="button"
              onClick={handleClean}
              aria-label={t("search.aria.clear")}
              className="absolute right-4 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-red-700 dark:text-gray-400 dark:hover:text-red-500 focus:outline-hidden focus:text-red-700 dark:focus:text-red-500"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClean(e);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
      </form>
      {searchResults}
    </div>
  );
};

export default memo(Buscador);
